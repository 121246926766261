import React, { useEffect, useRef, useState } from 'react'
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';


import { BiSolidVolumeMute , BiSolidVolumeFull } from "react-icons/bi";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import Card from "../Card";
import Doodles from '../../assets/images/vertendi/lotties/doodles.json'
import Lottie from "lottie-react";
import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'react-bootstrap';
import NoData from "../seperatemodals/nodata";
import config from '../config/config';
import bannerimg from "../../assets/images/banner.png";

import { IoMdClose } from "react-icons/io";


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from "react-toastify";

import { FaPause, FaPlay } from "react-icons/fa";
// import { IoMdPause } from "react-icons/io";
import { GiNextButton } from "react-icons/gi";
import { GiPreviousButton } from "react-icons/gi";

import { MdSkipPrevious } from "react-icons/md";
import { MdSkipNext } from "react-icons/md";
import { IoMdPlay } from "react-icons/io";
import { IoMdPause } from "react-icons/io";
import { TbRepeat, TbRepeatOff, TbRepeatOnce } from "react-icons/tb";
import { PiInfinityBold, PiInfoBold } from "react-icons/pi";


import { IoMdHeart } from "react-icons/io";
import { FaShareSquare } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { FaRegCopy } from "react-icons/fa";
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";

import { Share } from "../seperatemodals/Share";
import { LikeRef } from '../seperatemodals/LikeRef';
import { useDispatch, useSelector } from 'react-redux';


const MediaPlayer = (props) => {
    const [availNfts, setAvailNfts] = useState([])
    const dispatch = useDispatch()
    const [mute , Setmute] = useState(false)
    const [player, SetPlayerdata] = useState({})

    const payload = useSelector(
        (state) => state.LoginReducer.AccountDetails
      );
      const redux = useSelector(state => state.LoginReducer)
      const playerData  = useSelector(
        (state) => state.LoginReducer
      );
   
    useEffect(() => {
        const interval = setInterval(() => {
            getCurrentSlideIndex();


        }, 10000); // Check every 2 seconds

        return () => clearInterval(interval); // Clean up interval on unmount
    }, [availNfts]);

    const audioRef = useRef()
    const [openmodel , Setopenmodel] = useState(false)
    const [value, setValue] = useState(0);
    const [duration, setDuration] = useState(0)
    const [currentDuration, setCurrentDuration] = useState(0)
    const [play, setPlay] = useState(playerData?.player?.status)
    const [audiourl, Setaudiourl] = useState()
    const [openPlayer , SetopenPlayer ] = useState(true)

    const [shuffle, setShuffle] = useState(false)
    const [loop, setLoop] = useState(redux.player.loop)
    const playerRef = useRef()
    const [LikedTokenList, setLikedTokenList] = useState([]);
    const [loadingstate, setLoadingstate] = useState(true);
    var LikeForwardRef = useRef();

    const goNext = () => {
        // if (playerRef.current && playerRef.current.swiper) {
        //     playerRef.current.swiper.slideNext();
        // }

        // const datax = {
        //     loop: true,
        //     current: availNfts[0],
        //     next: availNfts[1],
        //     prev: availNfts[-1],
        //     list: availNfts.slice(2).concat(availNfts[0])
        // }
        // setAvailNfts(availNfts.slice(2).concat(availNfts[0]))
        // SetPlayerdata(datax)
      
let  arrayx =  availNfts.length > 0 ? availNfts : props.musicnfts
const cur = redux.player.current

console.log("arrayxarrayx" , arrayx)
arrayx.push(arrayx[0])
arrayx.push(cur)
arrayx.shift();

let hash = new Map();
// using hashMap for remove duplicate 
for (let i = 0; i < arrayx; i++){   
        hash.set(arrayx[i]._id, arrayx[i]);
}    

const valArray  =  hash.values() 

        dispatch({
            type: "player",
            player: { ...redux.player , ...{["current"] :  arrayx[0]  , ['list'] :  valArray } ,}
            
        })  

        SetPlayerdata({...redux.player , list :  valArray }) 
        setAvailNfts( valArray)
    };


    const goPrev = () => {
        // if (playerRef.current && playerRef.current.swiper) {
        //     playerRef.current.swiper.slidePrev();
        // }
const arrayx = redux.player.list.length > 0 ? redux.player.list : props.musicnfts
const len = arrayx.length ?? 1
const prevsongs = arrayx[len- 1 ] 
arrayx.pop();
arrayx.unshift(prevsongs)

let hash = new Map();
// using hashMap for remove duplicate 
for (let i = 0; i < arrayx; i++){   
        hash.set(arrayx[i]._id, arrayx[i]);
}    

const valArray = hash.values()
console.log("remmaingremmaing" , arrayx)
        dispatch({
            type: "player",
            player: { ...redux.player , ...{"current" :  arrayx[arrayx.length - 1 ] ,
                 'next' :  redux.player.current , 'list' :  valArray  } ,}
            
        })  


        SetPlayerdata({...redux.player , list :  valArray }) 
        setAvailNfts(valArray)



    };

    function LikeList(data) {
        setLikedTokenList(data);
    }

    const LikeAction = async () => {

        if (payload?.accountAddress) {
          var check = await LikeForwardRef.current.hitLike(player.current);
          toast.success("you " + check + "d token", { autoClose: 500, closeButton: true, closeOnClick: true });
        } else toast.error("Connect Wallet", { autoClose: 1000, closeButton: true, closeOnClick: true });
      };



    useEffect(() => {
        setAvailNfts(props?.musicnfts)
        setLoadingstate(props?.loadingstate)
        const nfts = props?.musicnfts // array of nft
        const remaing = nfts

        dispatch({
            type: "player",
            player: { ...redux.player , ...{["next"] : remaing  &&  remaing.length ?  remaing[0] :  {} , ["list"] : remaing , ['prev'] : redux.player.current } ,}
            ,
        })


    }, [props?.musicnfts])


    useEffect(() => {

        SetPlayerdata(redux.player)
console.log('SetPlayerreduxreduxdata' , redux.player)
      },[redux.player])


      useEffect(()=>{
        SetopenPlayer(true)

        setPlay(redux.player?.status)
if(redux.player?.status === true ){
    audioRef?.current?.play();
}

if(redux.player?.status === false ){
    audioRef?.current?.pause();
}

      },[redux?.player?.status ,audioRef?.current ,  redux?.player?.current])

      useEffect(() => {
        setLoop(redux.player.loop)  
    },[redux.player.loop])


    const updateLoop = () => {
        setLoop(!redux.player.loop)  

        dispatch({
            type: "player",
            player: { ...playerData.player , ...{["loop"] : !playerData.player.loop }}
            
        })
    }


    const handleSliderChange = (newValue) => {
const min = Math.min(newValue[0] , newValue[1])
const max = Math.max(newValue[0] , newValue[1])

        if(min != 0 ) {

            audioRef.current.currentTime = min
        setCurrentDuration(min);

        }else{
            audioRef.current.currentTime = max
        setCurrentDuration(max);

        }
        console.log('newValuenewValue' , newValue)
    };

    const getCurrentSlideIndex = () => {
        console.log("playerRefcurrenteeeee33", playerRef?.current?.swiper);
        // goNext();
        const index = playerRef?.current?.swiper.activeIndex
        if (availNfts?.length > 0) {

            console.log('dhasudas', availNfts[index], index)
            const product = availNfts[index]
            if (`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}` !== audiourl) {
                Setaudiourl(`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`)

            }
        }
    }

    const handleLoadMetaData = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration)
            setCurrentDuration(audioRef.current.currentTime)
        }
    }

    const handlePlayAudio = () => {
        setPlay(!play)
        if (!play) {
            if (audioRef.current) audioRef.current.play();
            // setPlay(!play)

        }
        else if (play) {
            if (audioRef.current) audioRef.current.pause();
        }

        dispatch({
            type: "player",
            player: { ...playerData.player , "status" : !play}
            
        })



    }

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            setCurrentDuration(audioRef.current.currentTime); // Update the current time
        }
        if (!loop) {
            if (audioRef.current.currentTime.toFixed(0) === duration.toFixed(0)) { setPlay(!play); goNext() }
        }
    };



    const gettheThumimage = (product) => {
       
        // Setaudiourl(`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`)
       
        return `${config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT_THUMB/${product?.NFTThumpImage}`
        // bannerimg
    }
    const getURl = (data)=> {
console.log("getURlgetURlgetURl",data)

const url = `${config.FRONT_URL+"/info/"+ data.CollectionNetwork+"/"+data.ContractAddress+"/" + data.NFTOwner + "/" + data.NFTId}`
return url     
}

const formatDuration = (time = 0) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const GetmusicUrl = (data)=>{
// `${config.IMG_URL}/nft/${redux?.player?.current?.NFTCreator}/Original/NFT/${redux?.player?.current?.CompressedFile}`
if(data?.category?.toLowerCase().includes('exclusive') || data?.category?.toLowerCase().includes('vertendi')){
    return `${config.IMG_URL}/nft/${data?.NFTCreator}/Compressed/NFT/${data?.CompressedFile}`

  }

return `${config.IMG_URL}/nft/${data?.NFTCreator}/Original/NFT/${data?.CompressedFile}`

  }
    // const getaudioUrls 

// useEffect(() => {
    
// })
    const closePlayer =  ()=>{
        if (audioRef.current) audioRef.current.pause();
        SetopenPlayer(false)

        dispatch({
            type: "player",
            player: { ...playerData.player , ...{ ["status"] : !play , ["show"] : false}}
            
        })
    }
    return (

        <>
{ redux.player.show && 
        <>
        {openmodel && 
        
        
        <Share 
        onhide={()=> Setopenmodel(false)}
        closePop={()=> Setopenmodel(false)}
        url={getURl(player?.current)}
        
        />
        }
        

        {payload && (
            <LikeRef
              ref={LikeForwardRef}
              setLikedTokenList={setLikedTokenList}
              LikeList={LikeList}
            />
          )}


    
            {player?.current ? <>




                <section className="pos_audio pt-3">
                    <div className='text-end px-5'>
                <IoMdClose onClick={()=> closePlayer()} className='text-white cursor-pointer'/>
                    </div>
                    <Row className="justify-content-center" >
                        <Col xl={11} lg={11} md={12} sm={12}>
                            <div className='eg__mediaPlayerBox d-flex justify-content-between flex-wrap align-items-center px-2 px-md-3 px-lg-0'>

                                <div className='f_img'>
                                    <div className='d-flex player-btn-style gap-2 align-items-center'>
                                        <img src={gettheThumimage(redux?.player.current)} className='imgset' />
                                        <div>
                                            <h4 className='firaFont text-white mb-1'>{redux?.player.current?.NFTName}</h4>
                                            <div className='eg__mediaPlayerDurationBox mt-4 d-flex '>
                                                <p className='firaFont text-white mr-2'>{redux?.player.current?.cur_owner_DisplayName}</p>
                                                <p className='m-0 firaFont eg__mediaPlayerDuration fw-400'>
                                                {formatDuration(currentDuration)}
                                                    
                                                    {/* {`00:${currentDuration < 10 ? '0' : ''}${currentDuration.toFixed(0)}`} */}
                                                    </p>
                                                <span className='text-white mx-1'>/</span>
                                                <p className='m-0 firaFont eg__mediaPlayerDuration fw-400'>
                                                {formatDuration(duration)}
                                                    {/* {`00${duration.toFixed(0)}`} */}
                                                    </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className='eg__mediaPlayerTopBox   d-flex justify-content-between align-items-center gap-4 mt-3 mt-sm-0'>


                                    <button className='eg__playerTopBtns' onClick={updateLoop} >
                                       { loop ? <TbRepeat className='repeat' />
                                        : 
                                        <TbRepeatOff className='repeat' />
                                    }

                                        
                                    </button>

                                    <button className='eg__playerTopBtns' onClick={() => goPrev()}>
                                        <MdSkipPrevious className='play_svg1' />
                                    </button>



                                    <button className='eg__playerTopBtns' onClick={() => handlePlayAudio()}>


                                        {player.status ? <IoMdPause className='play_svg' /> : <IoMdPlay className='play_svg' />}
                                    </button>



                                    <button className='eg__playerTopBtns' onClick={() => goNext()}>
                                        {/* <img src={require('../../assets/images/vertendi/forward.svg').default} className='img-fluid' /> */}
                                        <MdSkipNext className='play_svg1' />

                                    </button>

                                    {/* <button className='eg__playerTopBtns' onClick={updateLoop} >
                                     
                                        <PiInfinityBold className='infinity ' />

                                        
                                    </button> */}


                                </div>

                                <div className='f_img mt-3 mt-md-0'>
                                    <div className='d-flex gap-4 align-items-center'>
                                        


{  (LikedTokenList?.some(
              (value) => value.NFTId === player.current.NFTId
            ) ? (
                <IoMdHeart className='play_svgw' style={{ color:"red" }} onClick={()=> LikeAction()} />

            ) : (
                <IoMdHeart className='play_svgw'  onClick={()=> LikeAction()} />

            ))}


                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">
                                                Copy song link
                                            </Tooltip>}
                                        >
                                            <CopyToClipboard text={getURl(player?.current)}>
                                                <MdContentCopy className='play_svgw' />
                                            </CopyToClipboard>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Share to</Tooltip>}
                                        >
                                          <button className='p-0 fabuttons' onClick={()=> Setopenmodel(true)}><FaShareSquare className='play_svgw' /></button>  
                                        </OverlayTrigger>


                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Sound</Tooltip>}
                                        >
                                        {/* <button className='p-0 fabuttons'></button> */}
                                        <button className='p-0 fabuttons' onClick={()=> {
                                            audioRef.current.muted  = !audioRef.current.muted
                                            Setmute(!audioRef.current.muted)
                                            } }>
         { mute  ? 
                                            <BiSolidVolumeMute />  

         : 
<BiSolidVolumeFull />
         }  
                                            
                                            </button>
                                        
                                   
                                        </OverlayTrigger>
                                    </div>

                                </div>


                            </div>

                            <div className='eg__mediaPlayerProgerssBox mx-auto mt-2'>
                <audio controls 
                src={GetmusicUrl(redux?.player?.current)} 
                ref={audioRef} onLoadedMetadata={handleLoadMetaData} onTimeUpdate={handleTimeUpdate} loop={loop}
                className='d-none'
                />

                                {console.log(duration, "audiiiiiiii")}
                                <div>

                                    <RangeSlider
                                        rangeSlideDisabled={true}
                                        onInput={(e) => {
                                            console.log("onInputonInputonInput",e)
                                            handleSliderChange(e)}}
                                        min={0}
                                        max={duration}
                                        value={[0, currentDuration]}
                                        className={`single_thumb mt-4`} />
                                </div>

                            </div>

                        </Col>
                    </Row>
                </section>




            </> : <></>}

        </>}
        
         </>
    )
}

export default MediaPlayer

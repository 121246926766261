// 
import Lottie from 'lottie-react';
import React from 'react'
import Doodles from '../../assets/images/vertendi/lotties/doodles.json'
import PlainPlayer from '../../assets/images/vertendi/lotties/plainPlayer.json'
import { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getFaqList } from '../../actions/axioss/user.axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { GetRoadMap } from '../../actions/axioss/cms.axios';

const Roadmap = () => {
  const itemRefs = useRef([])
  const [faqList, setFaqList] = useState([]);
  const scrollToItem = (index) => {
    // itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });

    const element = itemRefs.current[index];
    const topOffset = 170;
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.scrollY - topOffset,
      behavior: 'smooth'
    });
    
  };
  

  const fetchDataroadMap = async () => {
    const data = await GetRoadMap()
    console.log("fetchDataroadMap", data)
    setFaqList(data.data)
  }

  useEffect(() => {
    fetchDataroadMap()
  }, [])
  return (
    // <div>

    <section className="ver__landingSection roadmpasect">
      <div className="rightDoodlesHolder">
        <Lottie animationData={Doodles} loop />
      </div>
      <div className="leftDoodlesHolder topCreatorLeftDoodles">
        <Lottie animationData={Doodles} loop />
      </div>
      <img src={require('../../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
      <img src={require('../../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
      <div className="hotAuctPlayerHolder">
        <Lottie animationData={PlainPlayer} loop />
      </div>
      <Container className="custom__vertContainer">


        <Row className="vert__roadmap">
          <Col xs={12} md={12} lg={4} className="mb-5 mb-lg-0 pb-5 pb-lg-0">
            <div className='vert__roadmap_left'>
              <h2 className="vert__subTitleGreen vert__subTtlGreeenNowrap crossfitFont mb-3">Road Map</h2>
              <p className="firaFont vert__cmnMidHint">Explore our Hot Auctions for coveted NFTs, from rare works by renowned artists to emerging talents. Lorem ipsum Experience the excitement as collectors bid on digital masterpieces.</p>

              <div className="vert__stepperHolder mt-4">
                <button className="vert__roadMapbtns" onClick={() => scrollToItem(0)}>
                  <i class="fa-solid fa-angle-left"></i>
                </button>
                {faqList?.length > 0 && faqList?.map((item, index) =>

                  <button className="vert__roadMapbtns" onClick={() => scrollToItem(index)}>
                    <i class="fa-solid fa-circle"></i>
                  </button>)}

                <button className="vert__roadMapbtns" onClick={() => scrollToItem(faqList?.length - 1)}>
                  <i class="fa-solid fa-angle-right"></i>
                </button>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={7} className="offset-lg-1">
            {faqList?.length > 0 && faqList?.map((item, index) =>
              <div className="vert__roadmapSingleItem" key={index} ref={(el) => itemRefs.current[index] = el}>
                <h4 className="vert__cmnSubTitlegreen crossfitFont">{item?.heading}</h4>
                <p className="firaFont vert__cmnMidHint">
                  {/* <div>{item.question}</div> */}

                  <div dangerouslySetInnerHTML={{ __html: item?.answer }}></div>
                </p>

              </div>
            )
            }
          </Col>
        </Row>

        <h2 className="vert__roadmapStrokeText crossfitFont text-center">Road Map</h2>

      </Container>
    </section>


  )
}

export default Roadmap
import React, { useEffect, useState } from "react";
import authorImg from "../assets/images/banner.png";
import Binance from '../assets/images/svg/binance.png'
import productImg from "../assets/images/product52.jpg";
import ethImg from "../assets/images/ethuu.png";
import Countdown from "react-countdown";
import { NavLink, useNavigate, Link } from "react-router-dom";
import ImgAudVideo from "../separate/ImgAudVideo";
import config from "./config/config";
import { address_showing, get_type_file, isEmpty, isMusic } from "../actions/common";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LikeRef } from "./seperatemodals/LikeRef";

import timer from "../assets/images/timer.png";

import author from '../assets/images/avatar.png';
import endless from '../assets/images/endless.png';
import CardImage from "../separate/cardimage";

import Backgroundbg from '../assets/images/vertendi/emtybg.png';
import Backgroundplay from '../assets/images/vertendi/nftCardBg.png';
import Backgroundpause from '../assets/images/vertendi/pauseimg.png';

export default function Card({
  product,
  index,
  LikeList,
  LikedTokenList,
  setLikedTokenList,
  LikeForwardRef,
  from,
  playerBtn,
  
}) {

  const dispatch = useDispatch()

  const payload = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const playerData  = useSelector(
    (state) => state.LoginReducer
  );

  const [view , setView ] = useState(`url(${Backgroundplay})`)

  
 



const PlayThis =(product)=>{
  if(from !== 'landing'){
    return null 
  }
  // on -> play
  // need to check current song == song if true status is need to change false


if(!isEmpty(playerData?.player?.current)){
  if(JSON.stringify(playerData?.player?.current)?.includes(product.NFTId)){
    dispatch({
      type: "player",
      player: { ...playerData.player , ...{["current"] :  product , ['show'] : true ,  ["status"] : !playerData.player.status}}
      
  })



  }else{
    dispatch({
      type: "player",
      player: { ...playerData.player , ...{["current"] :  product , ["status"] : true , ['show'] : true }}
      
  })
  }

}else{

  dispatch({
    type: "player",
    player: { ...playerData.player , ...{["current"] :  product , ["status"] : true , ['show'] : true }}
    
})
}

}

  const LikeAction = async (e) => {
    e.stopPropagation()
    if (payload?.accountAddress) {
      var check = await LikeForwardRef.current.hitLike(product);
      toast.success("you " + check + "d token", { autoClose: 500, closeButton: true, closeOnClick: true });
    } else toast.error("Connect Wallet", { autoClose: 1000, closeButton: true, closeOnClick: true });
  };

  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(days)} d</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };

  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };
  const push = useNavigate();



  // PlayThis

// useEffect(()=>{
//   if(JSON.stringify(playerData?.player?.current)?.includes(product.NFTId)){
// if(playerData?.player?.status){
//   setView(`url(${Backgroundplay})`)

// }else{
//   setView(`url(${Backgroundpause})`)

// }

//   }else{

//     setView(`url(${Backgroundbg})`)
//   }
// },[playerData?.player?.status])

useEffect(() => {
  if (!playerData?.player?.current) {
    // Exit the effect early if `current` is undefined
    return () => {}
  }

  const currentPlayerNFTs = playerData?.player?.current;
  if (JSON.stringify(currentPlayerNFTs)?.includes(product.NFTId) ) {
    if (playerData?.player?.status) {
      setView(`url(${Backgroundpause})`);
    } else {
      setView(`url(${Backgroundplay})`);
    }
  } else {
    setView(`url(${Backgroundplay})`);
  }

  
}, [playerData?.player?.current , playerData?.player?.status]);


useEffect(() => {
  const preventContextMenu = (event) =>  event.preventDefault();
    
    
  const images = document.querySelectorAll('img');

  images.forEach((image) => {
      image.addEventListener('contextmenu', preventContextMenu);
  });
  
  return () => {
      images.forEach((image) => {
          image.removeEventListener('contextmenu', preventContextMenu);
      });
  };

}, []);

const playe = `url(${Backgroundplay})`
  return (

      <div  onClick={()=> PlayThis(product)}>


              
      {payload && index === 0 && (
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          LikeList={LikeList}
        />
      )}

     {view  &&  <div className="sc-product style1 dg vertendi_card"  style={
        
        {
               background: `${from === 'landing' ?  view :  playe  }` 
            
            }}>
      <div className="product-button vertendiCardBidBtn d-flex justify-content-center align-items-center">
    
              <Link
                to={`/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`}
                state={{ state: product }}
                data-toggle="modal"
                data-target="#popup_bid"
                className={`tf-button vertendiBtn crossfitLight ${playerBtn && 'd-none'}`}
              >
              <img src={require('../assets/images/vertendi/bid.svg').default} className="img-fluid mr-2" />
             
                {" "}
                <span className="icon-btn-product"></span>
       
                {( payload && product?.NFTOwner == payload?.accountAddress )
                  ? "Owned"
                  : product?.PutOnSaleType == "FixedPrice"
                    ? "Buy Now"
                    : "Place Bid"}
              </Link>
            </div>
        <div className="top vertendi__liker">

          {from !== "usercollection" &&
            (LikedTokenList?.some(
              (value) => value.NFTId === product.NFTId
            ) ? (
              <div className="wish-list active">

                <span className="heart-icon"
                  onClick={(e) => LikeAction(e)}></span>
              </div>
            ) : (
              <div className="wish-list">

                <span className="heart-icon"
                  onClick={(e) => LikeAction(e)}></span>
              </div>
            ))}
        </div>
        <div className="features vertendi__imgHolder">
          <div
            className="product-media  audImgVid vertendi__audImgVid"
            onClick={() => {
              !isMusic(product?.CompressedFile) &&
                push(
                  `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`, { state: product}
                );
            }}
          >
       

       <Link className="firaFont vertendiCardName"
              to={
                product.from !== "othercollection" ?
                 `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : 
                 (payload?.accountAddress &&payload?.accountAddress === product?.NFTCreator) ? 
                    `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
              }>
            {from === "usercollection" ? (
              <iframe style={{
                background: 'url(' + product?.Image + ')', backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%", maxHeight: 288, minHeight: 288, minWidth: 288, maxWidth: 288, borderRadius: 15
              }} height="200" width="300" title="Iframe Example" id="myiFrame"></iframe>
             
            ) : (
              <CardImage
              from={from}
                file={product?.CompressedFile.split(':')[0] == 'https' ? product?.CompressedFile : `${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`}
                origFile={product?.NFTOrginalImage.split(':')[0] == 'https' ? product?.NFTOrginalImage : `${config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT/${product?.NFTOrginalImage}`}
                 thumb={`${config.IMG_URL}/nft/${product.NFTCreator}/Original/NFT_THUMB/${product?.NFTThumpImage}`}

                type={get_type_file(product?.CompressedFile)}
              />
            )}
            </Link>


          </div>

        </div>

        <div className="bottom pads vertendiCardCont">
          <div>

            <Link className="firaFont vertendiCardName"
              to={product.from !== "othercollection" ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : (payload?.accountAddress &&payload?.accountAddress === product?.NFTCreator) ? `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}` : "#"
              }>
              {product?.NFTName?.length > 13 ? product?.NFTName?.substring(0, 10) + "..." : product?.NFTName}
            </Link>
          </div>
          <div className="details-product">
            <div className="author">

              <div className="avatar">
                <img src={product?.cur_owner_Profile ? `${config.IMG_URL}/user/${product.NFTOwner}/profile/${product.cur_owner_Profile}` : config.profile} alt="images" />
              </div>

              <div className="content">
                <div className="position">
                  <Link className="firaFont vertendiCardCreator" to={ `${'/profile'}${'/'}${product?.cur_owner_CustomUrl ? product?.cur_owner_CustomUrl : product?.tokencreator_list?.CustomUrl ?  product?.tokencreator_list?.CustomUrl :  product?.CustomUrl}`}>

                  by {from === "usercollection"
                    ? address_showing(
                      product.OwnerName
                        ? product.OwnerName
                        : product.NFTOwner
                    )
                    : product &&
                    (product.Creator_DisplayName

                      ? product.Creator_DisplayName

                      : product?.Displayname ? product?.Displayname :  product?.NFTCreator?.substring(0, 5) +
                      "..." +
                      product?.NFTCreator?.substring(38, 42))}
                      </Link>
                </div>

              </div>
            </div>


          </div>

          <div className="d-flex justify-content-between">
            {(product.PutOnSaleType == "FixedPrice" || product.PutOnSaleType == "TimedAuction" || product?.highbidamount) ?
              <div className="current-bid">

                <div className="subtitle subss firaFont vertendiCardLabel">
                  {product.PutOnSaleType == "FixedPrice"
                    ? "Price"
                    : product.PutOnSaleType == "TimedAuction"
                      ? "Current Bid"
                      : product.PutOnSale == "false" &&
                      product?.highbidamount &&
                      "High bid"}

                </div>
                <div className="name">

                </div>
                <div className="price">
                  <span className="cash cashsize firaFont" >
                    {((product?.NFTPrice !== "" && product?.NFTPrice !== "0" && product?.NFTPrice !== 0) || product?.highbid) && (
                      <span className="icon" style={{ marginLeft: "4px" }}>
              
                      </span>
                    )}

                    {product.PutOnSaleType == "FixedPrice" ? (
                      `${product?.NFTPrice}  ${product?.CoinName.slice(0, 11)}`
                    ) : product.highbidamount ? (
                      `${product?.highbidamount} ${product?.highbidcoin.slice(0, 11)}`
                    ) : product.PutOnSaleType == "TimedAuction" ? (
                      `${product.NFTPrice} ${product?.CoinName.slice(0, 11)}`
                    ) : (
                      <></>
                    )}
      
                  </span>

                </div>
              </div>
              :
              <div className="current-bid">

                <p className="subtitle firaFont vertendiCardLabel m-0">
                  Auction
                </p>
                <div className="price text-center">
                  <img src={endless} className="endless" />
                </div>
              </div>}

            <div className="vertendiCard__timer">

                {product.PutOnSaleType === "TimedAuction" &&
                
            new Date(product.EndClockTime).getTime() >= Date.now() && (
              <div className="featured-countdown firaFont">


                {new Date(product?.ClockTime) > Date.now() ? (

                  <span className="js-countdown firaFont">Auction Starts in {Math.ceil((new Date(product.ClockTime).getTime() - new Date(Date.now()).getTime()) / (1000 * 3600 * 24))} Days</span>
                )
                  :
                  ((new Date(product?.EndClockTime) > Date.now()) ?
                    <span className="js-countdown">
          
                      {new Date(
                        product?.EndClockTime
                      ).getTime() >= Date.now() && (
                          <Countdown
                            date={product?.EndClockTime}

                            renderer={renderer}
                          />
                        )}
                    </span>
                    :
                    <span className="js-countdown">Auction Ended</span>
                  )}
              </div>
            )}
            </div>

          </div>

        </div>

      </div>}


    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import VertHeader from "../separate/VertHeader";
import { Col, Container, Dropdown, NavLink, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import Card from "./Card";
import { Token_List_Func } from "../actions/axioss/nft.axios";
import NoData from "./seperatemodals/nodata";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Lottie from "lottie-react";
import CDRoller from '../assets/images/vertendi/lotties/cdRoller.json'
import Speaker from '../assets/images/vertendi/lotties/speaker.json'
import Doodles from '../assets/images/vertendi/lotties/doodles.json'
import PausePlay from '../assets/images/vertendi/lotties/pausePlay.json'
import MusicSymbol from '../assets/images/vertendi/lotties/musicSym.json'
import Player from '../assets/images/vertendi/lotties/player.json'
import PlainPausePlay from '../assets/images/vertendi/lotties/plainPasePlay.json'
import PlainPlayer from '../assets/images/vertendi/lotties/plainPlayer.json'
import Clipping from '../assets/images/vertendi/lotties/clipping.json'
import BlogCard from "./BlogCard";
import Footer from "../separate/Footer";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Header from "../separate/Header";
import { getabouuser, getarticle } from "../actions/axioss/cms.axios";
import TopCreator from "./componets/topcreators";
import AboutLanding from "./componets/landingAbout";
import HotAction from "./componets/hotaction";
import Roadmap from "./componets/roadmap";
import config from  './config/config'
import MediaPlayer from "./componets/MediaPlayer1";
import Bannermusic from "../assets/images/vertendi/bannermusic.gif";
const Landing = () => {
  const [loadingstate, setLoadingstate] = useState(true);
  const [category, setCategory] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [filter, setFilter] = useState("recentcreated");
  var [filtershow, setFiltershow] = useState("Recently Created");
  const { Categorys } = useSelector((state) => state.LoginReducer);
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { Category } = useParams();
  const [TabName, SetTabName] = useState("All");
  const [blogData,setBlogData] = useState([ ])

  const itemRefs = useRef([])
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Tokens, SetTokens] = useState({
    All: { loader: true, page: 1, list: [] },
  });
const [musicnfts , SetMusicPlayer] = useState([])     
const [audiourl , Setaudiourl] = useState()


  var LikeForwardRef = useRef();
  const playerRef = useRef()

  const scrollToItem = (index) => {
    itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  };

  console.log("category", payload, Category);





  const fetchBlogData =async()=>{
    let aboutusers = await getarticle()
    console.log("aboutusers",aboutusers.data)
    setBlogData(aboutusers.data)
  }

  useEffect(()=>{
    fetchBlogData()
  },[])


  useEffect(() => {
    const interval = setInterval(() => {
      getCurrentSlideIndex();
     

    }, 2000); // Check every 2 seconds

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [musicnfts]);

  const getCurrentSlideIndex = ()=>{
    console.log("playerRefcurrenteeeee33" , playerRef?.current?.swiper);
    // goNext();
    const index =  playerRef?.current?.swiper.activeIndex
      if(musicnfts?.length > 0){
      
       console.log('dhasudas' , musicnfts[index] , index)
          const product =  musicnfts[index]
        if(`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}` !== audiourl){
        Setaudiourl(`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`)
          
        }
      }
  }





  useEffect(() => {
    if (Category) {
      if (Category !== TabName) {
        SetTabName(Category);
        Explore(1, Category);
      }
    } else {
      Explore();
    }
  }, [Category]);

  useEffect(() => {
    // if (typeof Tokens[TabName] == "undefined") {
    if (
      typeof Tokens[TabName] == "undefined" ||
      Tokens[TabName].filter !== filter
    ) {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    }
  }, [TabName, filter]);
    const Explore = async (data, tab) => {
    setLoadingstate(true);

    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 12,
      ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
      page: page ?? 1,
      from: "Explore",
      filter: filter,
    };
    let Resp = await Token_List_Func(SendDATA);
    SendDATA.TabName = 'exclusiveMusic'
    let player = await Token_List_Func(SendDATA);
    console.log("playeexclusivemusicr",player)
    SetMusicPlayer(player.data)


    if (Resp?.data) {
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [...Tokens[TabName].list, ...Resp.data],

            loader:
              Resp.data.length === 0 ||
              (SendDATA.TabName === "usercollection" && Resp.cursor == null)
                ? false
                : true,
            page: Tokens[TabName].page,
            filter: filter,
          },
        },
      });
      setLoadingstate(false);
    } else
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: Tokens[TabName].list,
            loader: false,
            page: Tokens[TabName].page,
            filter: filter,
          },
        },
      });
    setLoadingstate(false);
  };

  function LikeList(data) {
    setLikedTokenList(data);
  }



  console.log("tokeokokeokoekoeke", Tokens);
  return (
    <>
      <Container fluid className="p-0">
        <Header />
        <section className="ver__landingSection vert__bannerSection">
          <div className="pauseplayHolder">
            <Lottie animationData={PlainPausePlay} loop />
          </div>
          <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          
          <img src={require('../assets/images/vertendi/squareBg.png')} className="banner__squareBg"/>
          <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall" />
          <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall" />
          {/* <img src={require('../assets/images/vertendi/bannerHand.png')} className="bannerHandImg" /> */}
          <div className="banner__cdHolder banner__cdHolder1">
            <div className="banner__cdPlate d-flex justify-content-center align-items-center">
              <Lottie animationData={CDRoller} loop />
            </div>
          </div>
          <div className="banner__cdHolder banner__speakerHolder">
            <div className="banner__cdPlate d-flex justify-content-center align-items-center">
              <Lottie animationData={Speaker} loop />
            </div>
          </div>
          {/* <div className="banner__cdHolder banner__cdHolder2">
            <div className="banner__cdPlate d-flex justify-content-center align-items-center">
              <Lottie animationData={CDRoller} loop />
            </div>
          </div> */}
          <div className="banner__cdHolder banner__cdHolder3">
            <div className="banner__cdPlate d-flex justify-content-center align-items-center">
              <Lottie animationData={CDRoller} loop />
            </div>
          </div>
          <Container className="custom__vertContainer">
            <Row className="align-items-center">
              <Col xl={{span:7}}>
                <div className="vert__bannerTextCenterer">
                <div className="vert__bannerTextLottieHolder">
                  {/* <div className="vert__clippingJsonHolder"> */}
                  {/* <Lottie animationData={Clipping} className="vert__clippingJsonHolder" /> */}
                  {/* </div> */}
                <h1 className="vert__bannerGreen helveticaBold text-center m-0">
                  {/* Digital NFTs<br /> AI Music */}
                  <span>
                    <img src={Bannermusic} alt="Music" className="img-fluid bannermusic" />
                    {/* Digital NFTs */}
                    Music Market
                  </span> 
                  {/* For Your Music */}

                </h1>
                </div>
                </div>
                <p className="vert__bannerHint firaFont mt-4 text-center">
                  Covers, original music, <br /> exclusive songs & albums
                  {/* Create, Sell, Buy, Collect */}
                </p>
                <p className="vert__bannerGreen_sub firaFont mb-0 text-center">
                  Start your journey to music ownership today
                </p>
                

              </Col>
              <Col xs={12} md={12} lg={7} xl={5}>
                <div className="vert__banner_contents">
                  <div className="vert__banner_contentbox">
                    <p className="vert__banner_contenthead">For Creators</p>
                    <p className="firaFont vert__cmnHint">
                      Bring your music to the blockchain.
                    </p>
                    <p className="firaFont vert__cmnHint">
                      Ensure exclusive ownership, sell copyrights, earn royalties with unique music NFTs.
                    </p>
                  </div>
                  <div className="vert__banner_contentbox">
                    <p className="vert__banner_contenthead">For Musicians & Producers</p>
                    <p className="firaFont vert__cmnHint">
                      Own the rights and release the music you love.
                    </p>
                    <p className="firaFont vert__cmnHint">
                      Publish on platforms like Youtube and Spotify under your name - invest in future hits today.
                    </p>
                  </div>
                </div>
                {/* <div className="text-center">
                  <img src={require('../assets/images/vertendi/bannerHand.png')} className="bannerHandImg" />
                </div> */}
              </Col>
            </Row>

            <Row className="banner__rowTopper">
              <Col xl={12}>
                <div className="banner__swiperHolder">
              <Swiper navigation={true} modules={[Navigation,Autoplay]} className="mySwiper bannerSwiper"
              // autoplay={{
              //   delay: 2000,
              //   disableOnInteraction: false,
              // }}
              speed={2000}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                400: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                },
                550:{
                  slidesPerView: 1.6,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 1.9,
                  spaceBetween: 20,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1000: {
                  slidesPerView: 2.8,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3.3,
                  spaceBetween: 20,
                },
                1300: {
                  slidesPerView: 3.7,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 3.8,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1700: {
                  slidesPerView: 4.5,
                  spaceBetween: 20,
                },
                1800: {
                  slidesPerView: 4.6,
                  spaceBetween: 20,
                },
                1900: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              
              }}
              >
                                   {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    <div className="load-more">
                    </div>
                  </div>
                </>
              ) : TabName && Tokens[TabName]?.list.length > 0 ? (
                Tokens[TabName]?.list.map((item, index) => (
                  <SwiperSlide>
                    <Card
                      product={item}
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                      from="landing"

                    />
                    </SwiperSlide>
                ))
              ) : (
                <NoData />
              )}

      </Swiper>
      <div className="banner__swiperBlur"></div>
      </div>
              </Col>
              <Col lg={12} xl={0} className="d-none justify-content-center d-xl-none mt-5 pt-4 mt-xl-0 pt-xl-0">
                <img src={require('../assets/images/vertendi/bannerHand.png')} className="img-fluid bannerHandImgmobile" />
              </Col>
             
            </Row>
          </Container>
        </section>


        <section className="ver__landingSection">
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder topCreatorLeftDoodles">
          <Lottie animationData={Doodles} loop />
          </div>
          
          <img src={require('../assets/images/vertendi/squareBg.png')} className="banner__squareBg topCreatorSquareBg"/>
          <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
          <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
          <div className="musicSymHolder">
              <Lottie animationData={MusicSymbol} loop />
          </div>

          <div className="greenPlayerHolder">
              <Lottie animationData={Player} loop />
          </div>
          <div className="plainPausePlayHolder">
              <Lottie animationData={PlainPausePlay} loop />
          </div>
          <div className="playerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
              <Container className="custom__vertContainer">
          <div className="vertendi__hotNftsHolder">
            <div className="vertendi__hotNftSwiperHolder">
            <h2 className="vert__hotNfts crossfitFont">HOT NFTs</h2>
            <img src={require('../assets/images/vertendi/greenArrow.png')} className="vert__greenArrow" />
            <Swiper navigation={true} modules={[Navigation,Autoplay]} className="mySwiper bannerSwiper"
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              speed={2000}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                400: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                },
                550:{
                  slidesPerView: 1.6,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 1.9,
                  spaceBetween: 20,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1000: {
                  slidesPerView: 2.8,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3.3,
                  spaceBetween: 20,
                },
                1300: {
                  slidesPerView: 3.7,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 3.8,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1700: {
                  slidesPerView: 4.5,
                  spaceBetween: 20,
                },
                1800: {
                  slidesPerView: 4.6,
                  spaceBetween: 20,
                },
                1900: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              
              }}
              >
                                   {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    <div className="load-more">
                    </div>
                  </div>
                </>
              ) : TabName && Tokens[TabName]?.list.length > 0 ? (
                Tokens[TabName]?.list.map((item, index) => (
                  <SwiperSlide>
                    <Card
                      product={item}
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                      from="landing"

                    />
                    </SwiperSlide>
                ))
              ) : (
                <NoData />
              )}

      </Swiper>
      </div>

      <NavLink href='/explore' className="mt-5 pt-5">
      <div className="text-center">

  <button className="vert__bannerBtn firaFont">See More

  <img src={require('../assets/images/vertendi/roundCross.svg').default} className="ver__bannerArrow ml-3" />
  
  </button>
    
      </div>
      </NavLink>

      {/* <div className="ver__topCreatorsCont mt-5">
        <h2 className="vert__subTitleGreen vert__subTtlGreeenNowrap crossfitFont">Top <br className="d-none d-md-block" />Creators</h2>
        <div>
        <p className="firaFont vert__cmnHint">Explore our Hot Auctions for coveted NFTs, from rare works by renowned artists to emerging talents.</p>
        <p className="firaFont vert__cmnHint mb-2">Lorem ipsum Experience the excitement as collectors bid on digital masterpieces.</p>
      </div>
      </div>

      <div className="vert__topCreatorGrid">
        {topCreators?.map((item) => 
        <div className="vert__topCreatorSingle mb-4 mb-xl-0">
          <img src={item?.img} className="vert__topCreatorImg" />
          <p className="firaFont vert__topCreatorName text-center mb-3">{item?.name}</p>
        </div>
        )}
      </div> */}
      <TopCreator/> 
          </div>
          </Container>
        </section>


<AboutLanding />

          {/* hot auction section */}
          {/* <section className="ver__landingSection">
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder topCreatorLeftDoodles">
          <Lottie animationData={Doodles} loop />
          </div>
          <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
          <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
          <div className="hotAuctPlayerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
          <Container className="custom__vertContainer">
          <div className="ver__topCreatorsCont mt-5">
        <h2 className="vert__subTitleGreen vert__subTtlGreeenNowrap crossfitFont">Hot <br className="d-none d-md-block" />Auctions</h2>
        <div>
        <p className="firaFont vert__cmnHint">Explore our Hot Auctions for coveted NFTs, from rare works by renowned artists to emerging talents.</p>
        <p className="firaFont vert__cmnHint mb-2">Lorem ipsum Experience the excitement as collectors bid on digital masterpieces.</p>
      </div>
      </div>

      <Row className="mt-5">
        <Col lg={8} className="mb-4 mb-lg-0">
          <div className="vert__hotAuctTabHolder pb-4">
            {tabBtn?.map((item) => 
            <button className={`vert__cmnTabBtns helveticaFont ${item === activeTab && 'active'}`} onClick={() =>setActiveTab(item)}>{item}</button>
          )}
          </div>
        </Col>
        <Col lg={4} className="d-flex justify-content-end align-items-center">
        <Dropdown className="vert__cmnDrop firaFont">
      <Dropdown.Toggle id="dropdown-basic">
        Recently Created
      </Dropdown.Toggle>

      <Dropdown.Menu className="vert__cmnDropMenu">
        <Dropdown.Item className="vert__dropItem">Recently Created</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem">Bid Low to High</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem">Bid High to Low</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem">Oldest</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
        </Row>

      <Swiper navigation={true} modules={[Navigation,Autoplay]} className="mySwiper bannerSwiper mt-5"
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              speed={2000}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                400: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                },
                550:{
                  slidesPerView: 1.6,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 1.9,
                  spaceBetween: 20,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1000: {
                  slidesPerView: 2.8,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
                1300: {
                  slidesPerView: 3.6,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 3.8,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 4.2,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4.6,
                  spaceBetween: 20,
                },
                1800: {
                  slidesPerView: 4.8,
                  spaceBetween: 20,
                },
                1900: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              
              }}
              >
                                   {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    <div className="load-more">
                    </div>
                  </div>
                </>
              ) : TabName && Tokens[TabName]?.list.length > 0 ? (
                Tokens[TabName]?.list.map((item, index) => (
                  <SwiperSlide>
                    <Card
                      product={item}
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                    />
                    </SwiperSlide>
                ))
              ) : (
                <NoData />
              )}

      </Swiper>
      <NavLink href='/explore' >
      <div className="hotAuctBtnHolder">

<button className="vert__bannerBtn firaFont">See More<img src={require('../assets/images/vertendi/roundCross.svg').default} className="ver__bannerArrow ml-3" /></button>
      </div>
</NavLink>

            </Container>
            </section> */}
            < HotAction />
          {/* end of hot auction section */}


          {/* roadmap section */}
       <Roadmap />
          {/* end of roadmap section */}


          {/* live auctio section */}
          {/* <section className="ver__landingSection">
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder topCreatorLeftDoodles">
          <Lottie animationData={Doodles} loop />
          </div>
          <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
          <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
          <Container className="custom__vertContainer">
          <div className="ver__topCreatorsCont mt-5">
        <h2 className="vert__subTitleGreen vert__subTtlGreeenNowrap crossfitFont">Live <br className="d-none d-md-block" />Auctions</h2>
        <div>
        <p className="firaFont vert__cmnHint">Explore our Hot Auctions for coveted NFTs, from rare works by renowned artists to emerging talents.</p>
        <p className="firaFont vert__cmnHint mb-2">Lorem ipsum Experience the excitement as collectors bid on digital masterpieces.</p>
      </div>
      </div>

      <Row className="mt-5">
        <Col lg={8} className="mb-5 mb-lg-0">
          <div className="vert__hotAuctTabHolder">
            {tabBtn?.map((item) => 
            <button className={`vert__cmnTabBtns helveticaFont ${item === activeTab && 'active'}`} onClick={() =>setActiveTab(item)}>{item}</button>
          )}
          </div>
        </Col>
        <Col lg={4} className="d-flex justify-content-end align-items-center">
        <Dropdown className="vert__cmnDrop firaFont">
      <Dropdown.Toggle id="dropdown-basic">
        Recently Created
      </Dropdown.Toggle>

      <Dropdown.Menu className="vert__cmnDropMenu">
        <Dropdown.Item className="vert__dropItem">Recently Created</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem">Bid Low to High</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem">Bid High to Low</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem">Oldest</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
      </Row>

      <Swiper navigation={true} modules={[Navigation,Autoplay]} className="mySwiper bannerSwiper mt-5"
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              speed={2000}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                400: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                },
                550:{
                  slidesPerView: 1.6,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 1.9,
                  spaceBetween: 20,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1000: {
                  slidesPerView: 2.8,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
                1300: {
                  slidesPerView: 3.6,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 3.8,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 4.2,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4.6,
                  spaceBetween: 20,
                },
                1800: {
                  slidesPerView: 4.8,
                  spaceBetween: 20,
                },
                1900: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              
              }}
              >
                                   {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    <div className="load-more">
                    </div>
                  </div>
                </>
              ) : TabName && Tokens[TabName]?.list.length > 0 ? (
                Tokens[TabName]?.list.map((item, index) => (
                  <SwiperSlide>
                    <Card
                      product={item}
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                    />
                    </SwiperSlide>
                ))
              ) : (
                <NoData />
              )}

      </Swiper>

      <div className="hotAuctBtnHolder">

<button className="vert__bannerBtn firaFont">See More<img src={require('../assets/images/vertendi/roundCross.svg').default} className="ver__bannerArrow ml-3" /></button>
      </div>
            </Container>
            </section> */}
          {/* end of live auction section */}

          {/* player section */}

          {/* <section className="ver__landingSection">
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder topCreatorLeftDoodles">
          <Lottie animationData={Doodles} loop />
          </div>
          <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
          <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
          <Container className="custom__vertContainer">
            <h2 className="vert__greenBorderText crossfitFont text-center">Player</h2>
            <div className="vert__playerSwiperBtnHolder">
            <button className="vert__playerHandleBtnLeft" onClick={() => goPrev()}><img src={require('../assets/images/vertendi/playLeft.svg').default} className="img-fluid" /></button>
    <button className="vert__playerHandleBtnRight" onClick={() => goNext()}><img src={require('../assets/images/vertendi/play.svg').default} className="img-fluid" /></button>
            
            <Swiper navigation={true} ref={playerRef} modules={[Navigation , Autoplay]} className="mySwiper player__swiper mt-5"
        centeredSlides={true}
              speed={2000}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                400: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                },
                550:{
                  slidesPerView: 1.6,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 1.9,
                  spaceBetween: 20,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1000: {
                  slidesPerView: 2.8,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
                1300: {
                  slidesPerView: 3.6,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 3.8,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 4.2,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4.6,
                  spaceBetween: 20,
                },
                1800: {
                  slidesPerView: 4.8,
                  spaceBetween: 20,
                },
                1900: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              
              }}
              >
                                   {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    <div className="load-more">
                    </div>
                  </div>
                </>
              ) : 

            musicnfts && musicnfts.length > 0 ? (
              musicnfts.map((item, index) => ( <>
         

            <SwiperSlide>
              <Card
                playerBtn={playerBtn}
                product={item}
                index={index}
                LikeList={LikeList}
                LikedTokenList={LikedTokenList}
                setLikedTokenList={setLikedTokenList}
                LikeForwardRef={LikeForwardRef}
              />
              {console.log('itexxxm' , item , index)}
              </SwiperSlide>
      </>
                )
              )
              ) : (
                <NoData />
              )}

      </Swiper>
      </div> */}

{/* <Row className="justify-content-center mt-5">
  <Col lg={6}>
  <div className="vert__playerHolder">
  { musicnfts.length > 0 && <AudioPlayer className="firaFont" 
      loop
      showSkipControls
      showJumpControls
      showFilledProgress
      showDownloadProgress
    autoPlay
    src={audiourl}
    onPlay={e => console.log("onPlay")}
  /> }
  </div>
  </Col>
</Row> */}



{/* 
            </Container>
            </section> */}


    <MediaPlayer musicnfts={musicnfts} loadingstate={loadingstate} /> 
          
          {/* end of player section */}

          {/* blog section */}
          <section className="ver__landingSection">
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder topCreatorLeftDoodles">
          <Lottie animationData={Doodles} loop />
          </div>
          <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
          <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
          <Container className="custom__vertContainer">
          <div className="ver__topCreatorsCont align-items-lg-end">
        <h2 className="vert__subTitleGreen vert__subTtlGreeenNowrap crossfitFont text-center vert__landingBlogTitle">Stay Informed <br className="d-none d-lg-block" />and Inspired</h2>
        <div>
        <p className="firaFont vert__cmnHint vert__cmnHintXXL">
        Our blog section is a hub for insights, trends, and stories in the world of NFTs. Here, you'll find everything from beginner guides to deep dives into the latest developments in blockchain technology and digital art. Our blogs are crafted to educate, inspire, and empower our community, whether you're an artist, collector, or enthusiast.
        </p>
        <p className="firaFont vert__cmnHint mb-2"></p>
      </div>
      </div>


      <Swiper navigation={true} modules={[Navigation,Autoplay]} className="mySwiper bannerSwiper mt-5"
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              speed={2000}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                420: {
                  slidesPerView: 1.1,
                  spaceBetween: 20,
                },
                500: {
                  slidesPerView: 1.3,
                  spaceBetween: 20,
                },
                600:{
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 2.6,
                  spaceBetween: 20,
                },
                1000: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 3.6,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1300: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 4.5,
                  spaceBetween: 20,
                },
              
              }}
              >


                {blogData?.length? blogData?.map((item) => 
               <SwiperSlide>
                <BlogCard data={item} />
               </SwiperSlide>
               ) : <NoData/>}
      </Swiper>
            </Container>
            </section>
          {/* end of blog section */}
          <Footer/>
      </Container>
    </>
  );
};

export default Landing;
